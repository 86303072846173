import {StringSchema} from 'yup';
import * as yup from 'yup';

import {Order} from '../orders/order.class';
import {User} from '../users/user.class';

const invalidAddressCharRegex = /[.,?$!|]/;

/**
 * Provides standardized validation for customer account number
 * @param fieldRequired
 */
export const validateAccountNum = (fieldRequired?: boolean): StringSchema => {
    let accountNumValidation = yup.string().label(`Account Number`);

    // Add fieldRequired if specified
    if (fieldRequired) {
        accountNumValidation = accountNumValidation.required();
    }

    // Return completed validation schema
    return accountNumValidation.test(`invalid-account-number`, `Invalid Account Number`, (value) => {
        return value ? User.isValidAccountNumber(value) : true;
    });
};

/**
 * Provides standard validation for an address attention line
 */
export const validateAttn = (): StringSchema => {
    return yup.string().label(`Attention`).max(25);
};

/**
 * Provides standard validation for a shipping address line
 * @param loginName - If provided, will check to ensure shipping address doesn't contain user's email
 * @param fieldRequired - If this field should be treated as "required"
 */
export const validateBillToAddr = (loginName: string, fieldRequired?: boolean): StringSchema => {
    let billToValidation = yup.string().label(`Shipping address`);

    // Add fieldRequired if requested
    if (fieldRequired) {
        billToValidation = billToValidation.required();
    }

    // Return completed validation schema
    return billToValidation.max(25).notOneOf([loginName], `Billing address may not contain your e-mail address.`);
};

/**
 * Provides standard validation for a billing address city
 */
export const validateBillToCity = (): StringSchema => {
    return yup.string().label(`City`).required().max(18);
};

/**
 * Provides standard validation for a billing address state selection
 */
export const validateBillToSt = (): StringSchema => {
    return yup.string().label(`State`).required();
};

/**
 * Provides standard validation for an email address
 */
export const validateEmail = (): StringSchema => {
    return yup.string().label(`Email Address`).required().min(3).max(65).email();
};

/**
 * Provides standard validation for a list name
 */
export const validateListName = (): StringSchema => {
    return yup.string().label(`List Name`).required().max(50);
};

/**
 * Provides standard validation for a login name
 */
export const validateLoginName = (): StringSchema => {
    return yup.string().label(`Email Address`).required();
};

/**
 * Validates a new password to ensure it meets specs
 * @param loginname - Used to determine if contained in password
 */
export const validateNewPassword = (loginname: string): StringSchema => {
    loginname = loginname || ``;
    const badWords = [`parts`, `imper`, `1mper`, `1mp3r`, `system`, `passw`, `p455w`, `p@55`, `54321`, `suppl`, `welco`, `order`];
    return yup
        .string()
        .typeError(`Invalid Password`)
        .required(`This field is required`)
        .min(8, `Password must be a minimum of 8 characters`)
        .max(25, `Password must not exceed 25 characters`)
        .matches(/[0-9]/g, `Password must contain at least 1 number`)
        .test(`bad-words`, `Password may not contain: ${badWords.join(`, `)}`, (value) => {
            const foundBadWords = [];
            if (value) {
                for (const badWord of badWords) {
                    if (value.toLowerCase().indexOf(badWord.toLowerCase()) > -1) {
                        foundBadWords.push(badWord);
                    }
                }
            }
            return foundBadWords.length === 0;
        })
        .test(`no-login-name`, `Password may not contain part of your e-mail address`, (value) => {
            let isValid = true;
            if (value) {
                for (let i = 0; i < loginname.length - 3; i++) {
                    const usernameSection = loginname.substring(i, i + 4);
                    if (loginname !== `` && value.toLowerCase().indexOf(usernameSection) > -1) {
                        isValid = false;
                        break;
                    }
                }
            }
            return isValid;
        })
        .test(`consecutive-characters`, `Password may not contain consecutive numbers/letters (e.g. 123454321)`, (value) => {
            let isValid = true;
            if (value) {
                const lowerCaseLetters = `abcdefghijklmnopqrstuvwxyz`;
                const numbers = `0123456789`;
                const upperCaseLetters = `ABCDEFGHIJKLMNOPQRSTUVWXYZ`;
                const start = 2;
                let seq = `_` + value.slice(0, start);
                for (let i = start; i < value.length; i++) {
                    seq = seq.slice(1) + value.charAt(i);
                    if (lowerCaseLetters.indexOf(seq) > -1 || upperCaseLetters.indexOf(seq) > -1 || numbers.indexOf(seq) > -1) {
                        isValid = false;
                        break;
                    }
                }
            }
            return isValid;
        });
};

/**
 * Provides standard validation for entering a password
 */
export const validatePassword = (): StringSchema => {
    return yup.string().label(`Password`).required().max(25);
};

/**
 * Provides standard validation for a phone number
 */
export const validatePhoneNbr = (): StringSchema => {
    return yup.string().label(`Phone Number`).required().min(10).max(16);
};

/**
 * Provides standard validation for a PO number associated with an order
 * @param order
 */
export const validatePoNumber = (order: Order): StringSchema => {
    return yup
        .string()
        .test(`po-required`, `Must provide a PO number`, (po) => {
            return order.mustProvidePO || order.po ? !!po : true;
        })
        .test(`po-length`, `Cannot exceed 20 characters`, (po) => {
            return po ? po.length < 21 : true;
        })
        .test(`po-regex`, `Invalid PO number - ${order.poHelp}`, (po) => {
            return order.poRegex ? new RegExp(`${order.poRegex}`, `i`).test(po) : true;
        });
};

/**
 * Provides standard validation for a shipping address line
 * @param contactEmailFieldName - If provided, will check to ensure shipping address doesn't contain user's email
 * @param fieldRequired - If this field should be treated as "required"
 * @param loginName - If provided, will check to ensure shipping address doesn't contain user's email
 */
export const validateShipToAddr = (contactEmailFieldName?: string, fieldRequired?: boolean, loginName?: string): StringSchema => {
    let shipToValidation = yup.string().label(`Shipping address`);

    // Add fieldRequired if requested
    if (fieldRequired) {
        shipToValidation = shipToValidation.required();
    }

    // Add common validation schema
    shipToValidation = shipToValidation
        .test(`address-invalid-chars`, `Shipping address cannot contain . , ? $ ! or |`, (value) => {
            return !invalidAddressCharRegex.test(value);
        })
        .test(`min-val-if-populated`, `Shipping address must be at least 3 characters`, (value) => {
            if (value) {
                return yup.string().min(3).isValidSync(value);
            }
            return true;
        })
        .max(25)
        .test(`no-po-box`, `Cannot ship to a PO Box`, (value) => {
            return !Order.isPoBox(value);
        });

    // Add email address validation, via field
    const emailValidationMessage = `Shipping address may not contain your e-mail address.`;
    if (contactEmailFieldName) {
        shipToValidation = shipToValidation.when(contactEmailFieldName, {
            is: (value: string) => value,
            then: yup.string().notOneOf([yup.ref(contactEmailFieldName)], emailValidationMessage),
        });
    }

    // Add email address validation, via loginName
    if (loginName) {
        shipToValidation = shipToValidation.notOneOf([loginName], emailValidationMessage);
    }

    // Return completed validation schema
    return shipToValidation;
};

/**
 * Provides standard validation for a shipping address city
 */
export const validateShipToCity = (): StringSchema => {
    return yup
        .string()
        .label(`City`)
        .required()
        .test(`no-po-box`, `City cannot be a PO Box`, (value) => {
            return !Order.isPoBox(value);
        })
        .min(3)
        .max(18);
};

/**
 * Provides standard validation for a shipping address state selection
 */
export const validateShipToSt = (): StringSchema => {
    return yup
        .string()
        .label(`State`)
        .required()
        .test(`shipToState`, `We cannot accommodate customers from Hawaii, Alaska or Puerto Rico.`, (value) => {
            return !(value === 'AK' || value === 'PR' || value === 'HI');
        });
};

/**
 * Provides standard validation for a shipping address zip code in the US
 */
export const validateShipToZipUS = (): StringSchema => {
    return yup
        .string()
        .label(`Zip Code`)
        .required()
        .min(5)
        .max(10)
        .test(`us-zip-code`, `Please provide a valid zip code.`, (value) => {
            return Order.isValidUsZipCode(value);
        });
};

/**
 * Provides standard validation for a shipping address zip code in the US and Canada
 */
export const validateShipToZipUSandCA = (): StringSchema => {
    return yup
        .string()
        .label(`Zip Code`)
        .required()
        .min(5)
        .max(10)
        .test(`us-and-ca-zip-code`, `Please provide a valid zip code.`, (value) => {
            return Order.isValidUSAndCanadianZipCode(value);
        });
};

/**
 * Provides standard validation for a name
 * @param fieldRequired - If this field should be treated as "required"
 * @param overrideLabel
 */
export const validateName = (fieldRequired?: boolean, overrideLabel?: string): StringSchema => {
    let nameValidation = yup.string().label(overrideLabel || `Name`);

    // Add fieldRequired if specified
    if (fieldRequired) {
        nameValidation = nameValidation.required();
    }

    // Add common validation schema
    nameValidation = nameValidation
        .test(`min-val-if-populated`, `${overrideLabel || 'Name'} must be at least 3 characters`, (value) => {
            if (value) {
                return yup.string().min(3).isValidSync(value);
            }
            return true;
        })
        .max(25);

    // Return completed validation schema
    return nameValidation;
};
